import classNames from "classnames";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";

export interface ModalProps {
  show: boolean;
  toggle: (state: boolean) => void;
  title: string;
  closeable?: boolean;
  noBg?: boolean;
  bgClose?: boolean;
  className?: string;
  shareAction?: () => void;
  mobileFull?: boolean;
  hasModalHeaderMobile?: boolean;
  subTitle?: string;
}

const FullModal: FunctionComponent<ModalProps> = ({
  show,
  toggle,
  title,
  children,
  closeable = true,
  noBg = false,
  bgClose = true,
  className = "",
  mobileFull = false,
  hasModalHeaderMobile = false,
  subTitle,
  shareAction,
}) => {
  const [showModal, setShowModal] = useState(show);
  useEffect(() => {
    if (!show && show !== showModal) {
      setTimeout(() => {
        setShowModal(show);
      }, 301);
    } else {
      setShowModal(show);
    }
  }, [show]);

  const sizeClassesDefault = "bottom-0 w-full max-h-screen h-[100dvh] w-screen rounded-none";
  const sizeClassesMobile =
    "bottom-0 top-0 md:top-[80px] w-full md:w-11/12 md:max-w-[480px] max-h-screen h-[100dvh] md:h-auto md:max-h-[80vh] rounded-none md:rounded-15";

  const getSizeClasses = (mobileFull) => {
    const sizeClasses = mobileFull ? sizeClassesMobile : sizeClassesDefault;
    return sizeClasses;
  };
  const wrapperClasses = classNames(
    "bg-black bg-opacity-70 fixed top-0 left-0 right-0 bottom-0 z-[999] flex justify-center cursor-pointer items-start",
    {
      "modal-wrapper-anim": show,
      "modal-wrapper-anim-rev": !show,
      "bg-opacity-70 blur-5": !noBg,
      "bg-opacity-0": noBg,
    },
    className
  );

  const mainClasses = classNames(
    "bg-white w-full absolute sm:relative flex flex-col cursor-default sm:overflow-hidden",
    {
      "modal-body-anim": show,
      "modal-body-anim-rev": !show,
    },
    getSizeClasses(mobileFull)
  );

  return (
    <>
      {showModal ? (
        <div className={wrapperClasses} onClick={(e) => (bgClose && closeable ? toggle(false) : {})}>
          <div className={mainClasses} onClick={(e) => e.stopPropagation()}>
            <div
              className={classNames({
                "bg-white border-b border-grey-border w-full rounded-t-10 sm:rounded-t-15": !mobileFull,
                "bg-white border-b border-grey-border md:border-none md:bg-grey-modal rounded-t-10 sm:rounded-t-15":
                  mobileFull,
                  "hidden md:block": hasModalHeaderMobile
              })}
            >
              <div
                className={`w-full flex ${subTitle? "items-start" : "items-center"} justify-between mx-auto md:max-w-[1100px] lg:max-w-[1150px] py-4.5 sm:py-6 ${
                  mobileFull ? "px-5 md:px-6.25" : "px-5 sm:px-9 md:px-12.5"
                }`}
              >
                {shareAction ? (
                  <button className="text-black-placeholder no-outline rounded-full bg-grey-fields-100 p-2" onClick={shareAction}>
                    {/* prettier-ignore */}
                    <svg className="h-4.5 w-4.5 sm:h-5 sm:w-5" viewBox="0 0 22 22" fill="none">
                      <path d="M15.0701 8.1582C18.3701 8.44237 19.7176 10.1382 19.7176 13.8507V13.9699C19.7176 18.0674 18.0768 19.7082 13.9793 19.7082H8.01177C3.91427 19.7082 2.27344 18.0674 2.27344 13.9699V13.8507C2.27344 10.1657 3.6026 8.46987 6.8476 8.16737" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11 13.75V3.31836" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.0704 5.36234L10.9995 2.2915L7.92871 5.36234" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                ) : (
                  <div className="flex-col flex gap-1">
                    <h4 className="text-black text-left text-[17px] font-semibold flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis mr-5">
                      {title}
                    </h4>

                   {subTitle && <h4 className="text-black-secondary text-left text-[14px] font-semibold flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis mr-5">
                      {subTitle}
                    </h4>
                    }
                  </div>
                )}
                {closeable && (
                  <button
                    type="button"
                    onClick={() => toggle(false)}
                    className={`no-outline rounded-full ml-auto transition-all text-black-placeholder ${
                      mobileFull
                        ? "bg-grey-fields-100 md:bg-transparent p-2 md:p-0 h-7.5 w-7.5 sm:h-6.25 sm:w-6.25"
                        : "bg-grey-fields-100 p-2 h-7.5 w-7.5 sm:h-8 sm:w-8"
                    } hover:border-primary-500 hover:text-primary-500 flex items-center justify-center`}
                  >
                    {/* prettier-ignore */}
                    <svg width={22} className={`${mobileFull? "inline-block md:hidden" : "inline-block"}`} viewBox="0 0 22 22" fill="none">
                    <path d="M16.5 5.5L5.5 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.5 5.5L16.5 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                   {/* prettier-ignore */}
                    <svg
                      className={`${mobileFull ? "hidden md:inline-block" : "hidden"}`}
                      width="100%"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="white" stroke="currentColor" />
                      <path
                        d="M16.25 8.75L8.75 16.25"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.75 8.75L16.25 16.25"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

const ModalBody: React.FC<{
  className?: string;
  noPadding?: boolean;
  setRef?: (el?: HTMLDivElement) => void;
}> = ({ children, className, noPadding = false, setRef }) => {
  // const bodyRef = useRef();

  // useEffect(() => {
  //   if (bodyRef.current && setRef) setRef(bodyRef);
  // }, []);

  const bodyRef = useCallback((node) => {
    if (node !== null && setRef) {
      setRef(node);
    }
  }, []);

  return (
    <div className={`flex-auto overflow-y-auto ${noPadding ? "" : "p-5 sm:p-6.25"} ${className}`} ref={bodyRef}>
      {children}
    </div>
  );
};

const ModalFooter: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className={`flex items-center py-3 sm:py-3.75 px-5 sm:px-6.25 border-t border-grey-divider ${className}`}>
      {children}
    </div>
  );
};

const ModalHeaderMobile: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className={`w-full  border-b border-grey-border items-center justify-between mx-auto md:max-w-[1100px] lg:max-w-[1150px] py-4.5 sm:py-6 px-5 sm:px-9 md:px-12.5 flex md:hidden ${className}`}>
      {children}
    </div>
  );
};

export default FullModal;

export { ModalBody, ModalFooter, ModalHeaderMobile };
