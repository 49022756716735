import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalBody } from "../ui/modal-full";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { KeenSliderHooks, KeenSliderInstance } from "keen-slider";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  images: string[];
  current?: number;
  title?: string;
}

const ImageCarouselModal: React.FC<Props> = ({ show, toggle, images, current = 0, title }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created(slider) {
      updateSliderIndex(slider, current);
      setLoaded(true);
    },
  });

  const updateSliderIndex = (slider: KeenSliderInstance<{}, {}, KeenSliderHooks>, index) => {
    slider.moveToIdx(index);
    setCurrentSlide(index);
  };

  const checkRefAndSetCurrent = (index: number, timer: NodeJS.Timeout) => {
    if (instanceRef?.current) {
      updateSliderIndex(instanceRef?.current, index);
      clearInterval(timer);
    }
  };

  useEffect(() => {
    if (!show) return;

    const timer = setInterval(() => checkRefAndSetCurrent(current, timer), 100);
    return () => clearInterval(timer);
  }, [current, show]);

  return (
    <Modal title={title || "Product Images"} {...{ show, toggle }}>
      <ModalBody className="w-full px-5 sm:px-9 md:px-12.5 mx-auto md:max-w-[1100px] lg:max-w-[1150px] py-7.5 sm:py-10">
        <div className="keen-navigation-wrapper flex h-[90%]">
          <div ref={sliderRef} className="keen-slider">
            {images.map((image, index) => (
              <figure className="w-full keen-slider__slide relative flex items-center justify-center rounded-15">
                <img src={image} alt="" className={`rounded-15 md:max-w-full max-h-full`} />
              </figure>
            ))}
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
              />
            </>
          )}
        </div>
        {loaded && instanceRef?.current && (
          <div className="flex justify-center left-0 right-0 fixed bottom-10 w-full">
            <div className="keen-dots max-w-sm mt-3.75 rounded-full">
              {images.map((image, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={"keen-dot" + (currentSlide === idx ? " active" : "")}
                  ></button>
                );
              })}
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

interface ArrowProps {
  disabled: boolean;
  left?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Arrow: React.FC<ArrowProps> = ({ disabled, left, onClick }) => {
  const disabledClass = disabled ? "hidden" : "";
  return (
    <button
      onClick={onClick}
      className={`h-10 w-10 absolute top-[50%] rounded-full bg-primary-500 hover:bg-primary-700 flex items-center justify-center shadow-md text-white ${
        left ? "arrow--left" : "arrow--right"
      } ${disabledClass}`}
    >
      <svg className="w-[40%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        {left ? (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" fill="currentColor" />
        ) : (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" fill="currentColor" />
        )}
      </svg>
    </button>
  );
};
export default ImageCarouselModal;
